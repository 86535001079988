import React, { Fragment, useRef, useState, useEffect  } from "react";
import Footer from "../components/Footer";
import emailjs from '@emailjs/browser'; 

const Contact = () => {

    const form = useRef();
  const [message, setMessage] = useState(''); // State for success/error message
  const [error, setError] = useState(false); // State to track error

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_x643vog',   // Your Service ID
        'template_k541rcq',   // Your Template ID
        form.current,         // This is your form reference
        '1wCu-cKVcbGLd12eq'   // Your Public Key
      )
      .then(
        () => {
          setMessage('Your message has been sent successfully!'); // Success message
          setError(false); // No error
          form.current.reset(); // Reset the form after success
        },
        (error) => {
          setMessage('Failed to send the message. Please try again later.'); // Error message
          setError(true); // Set error state
        }
      );
  };

  // Clear the message after 5 seconds
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(''); // Clear the message
      }, 5000); // 5000ms = 5 seconds
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [message]);

    return (
        <Fragment>
            
            <div class="breadcumb-wrapper bread-two" style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url('/assets/img/added/ban.jpg')" }} >
                <div class="container z-index-common">
                    <div class="breadcumb-content ">
                        <h1 class="breadcumb-title">Contact Us</h1>
                        <div class="breadcumb-menu-wrap">
                            <ul class="breadcumb-menu">
                                <li><a href="/">Home</a></li>
                                <li>Contact</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section class="space-new contact-box_wrapper">
                <div class="outer-wrap">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="contact-box">
                                    <div class="contact-box_icon">
                                        <i class="fas fa-address-card"></i>
                                    </div>
                                    <h3 class="contact-box__title h5">Contact</h3>
                                    <ul class="contact-box_list">
                                        <li><a href="tel:+919974414718">+91 997 441 4718</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="contact-box">
                                    <div class="contact-box_icon">
                                        <i class="fas fa-envelope"></i>
                                    </div>
                                    <h3 class="contact-box__title h5">Email us</h3>
                                    <ul class="contact-box_list">
                                        <li><a href="mailto:organictoursindia@gmail.com">organictoursindia@gmail.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <div class="padding-b">
                <div class="container">
                    <form class="ajax-contact" ref={form} onSubmit={sendEmail}>
                        <div class="row justify-content-center text-center">
                            <div class="col-xxl-8 col-xl-6 col-lg-6 col-md-6">
                                <div class="">
                                    <span class="sec-subtitle">Contact Us</span>
                                    <h2 class="sec-title h1">Get In Touch</h2>
                                    {/* <p class="sec-text">We’d love to hear from you! Whether you have questions, need more information, or want to start planning your journey with us, feel free to reach out. Simply fill out the form below or email us at <a href="">organictoursindia@gmail.com</a>, and we’ll respond as soon as possible.</p> */}
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6 form-group">
                                <input type="text" placeholder="Enter Your First Name" name="first_name" id="fname" class="form-control" />
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="text" placeholder="Enter Your Last Name" name="last_name" id="lname" class="form-control" />
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="email" placeholder="Your Email" name="user_email" id="email" class="form-control" />
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="number" placeholder="Phone No" name="user_number" id="number" class="form-control" />
                            </div>

                            <div class="form-group col-12">
                                <textarea placeholder="Write Your Comment" name="message" id="message" class="form-control"></textarea>
                            </div>
                            {message && (
                               <div className={`alert ${error ? 'alert-danger' : 'alert-success'} mt-4`} role="alert">
                                 {message}
                               </div>
                             )}
                            <div class="col-md-auto pt-lg-3">
                                <button class="vs-btn style4" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
            
            <Footer />
            
        </Fragment>
    );
}

export default Contact;

