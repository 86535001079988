import React, { Fragment } from 'react'
import Logo from '../assets/logo.png'

function Header() {
    return (
        <Fragment>
            {/* <div class="vs-menu-wrapper">
                <div class="vs-menu-area text-center">
                    <button class="vs-menu-toggle"><i class="fal fa-times"></i></button>
                    <div class="mobile-logo">
                        <a href="index.html"><img src={Logo} alt="Travolo" /></a>
                    </div>
                    <div class="vs-mobile-menu">
                        <ul>
                            <li class="">
                                <a href="index.html">Home</a>
                            </li>
                            <li class="">
                                <a href="about.html">About Us</a>
                            </li>
                            <li>
                                <a href="contact.html">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <header class="vs-header header-layout2">
                <div class="header-top">
                    <div class="container">
                        <div class="row justify-content-between align-items-center">
                            <div class="col d-none d-lg-block">
                                <ul class="header-contact">
                                    <li>
                                        <i class="fas fa-envelope"></i>
                                        <a href="mailto: organictoursindia@gmail.com"> organictoursindia@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-auto">
                                <div class="header-social">
                                    <a href="https://www.facebook.com/organictoursindia" target="_blank" rel="noreferrer"><i class="fab fa-facebook-f"></i></a>
                                    <a href="/" target="_blank" rel="noreferrer"><i class="fab fa-instagram"></i></a>
                                    <a href="https://youtu.be/CnOJsVLJ6GA?si=8LPzoF5PWZlY9Vk1" target="_blank" rel="noreferrer"><i class="fab fa-youtube"></i></a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <a class="vs-btn style2" href="contact.html">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sticky-wrapper">
                    <div class="sticky-active">
                        <div class="container position-relative z-index-common">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto">
                                    <div class="vs-logo">
                                        <a href="index.html"><img src={Logo} alt="logo" /></a>
                                    </div>
                                </div>
                                <div class="col text-end p-right">
                                    <nav class="main-menu menu-style1 d-none d-lg-block">
                                        <ul>
                                            <li class="">
                                                <a href="index.html">Home</a>
                                            </li>
                                            <li class="">
                                                <a href="about.html">About Us</a>
                                            </li>
                                            <li>
                                                <a href="contact.html">Contact</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <button class="vs-menu-toggle d-inline-block d-lg-none">
                                        <i class="fal fa-bars"></i>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header> */}
        </Fragment>
    );
}

export default Header;