import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {

    const sliderSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (
        <Fragment>
            <Header />
            <div class="breadcumb-wrapper" style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url('/assets/img/added/ban.jpg')" }}>
                <div class="container z-index-common">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">About Us</h1>
                        <div class="breadcumb-menu-wrap">
                            <ul class="breadcumb-menu">
                                <li><a href="/">Home</a></li>
                                <li>Pages</li>
                                <li>About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section class="space bg-smoke shape-mockup-wrap">
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ bottom: "13%", right: "0%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="image-box1">
                                <img class="img1" src="assets/img/about/img-2-1.jpg" alt="image1" />
                                <img class="img2" src="assets/img/added/yoga.jpg" alt="image2" />
                                <div class="media-box1">
                                    <span class="media-info">Unveiling </span>
                                    <p class="media-text">Spirit of Bharat</p>
                                </div>
                                <div class="media-box2">
                                    <span class="media-info">Crafting </span>
                                    <p class="media-text">Unique Experiences</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="about-content">
                                <div class="title-area">
                                    <span class="sec-subtitle">We are Organic Tours India</span>
                                    <h2 class="sec-title h1">Discover the True Essence of Bharat </h2>
                                    <p class="sec-text">For centuries, Bharat has been misrepresented by the Western world, often depicted as a land of poverty and struggle. Bharat is far more than the limited portrayal seen from afar. It is the birthplace of profound human knowledge and spiritual practices that have shaped civilizations across the globe.</p>
                                    <p class="sec-text">At Organic Tours India, our mission is to reveal the real Bharat to the world. We invite you to experience a journey that uncovers the rich, authentic soul of this ancient land—a side of Bharat that remains largely unseen and untold.</p>
                                </div>
                                <ul class="about-list1">
                                    <li>Cultural Immersions</li>
                                    <li>Spiritual Journeys</li>
                                    <li>Wellness Retreats</li>
                                    <li>Culinary Adventures</li>
                                    <li>Nature & Adventure Tours</li>
                                    <li>Customized Itineraries</li>
                                    <li>Community Engagement</li>
                                    <li>Festivals & Events</li>
                                </ul>
                                <a href="/destination" class="vs-btn style4">Know More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="space shape-mockup-wrap fact--layout1 bg-black-back-about">
                <div class="shape-mockup d-none d-xl-block z-index-negative" style={{ top: "0%", right: "0%", left: "0%" }}>
                    <img class="w-100" src="assets/img/shape/fact-shape-1.svg" alt="svg" />
                </div>
                <div class="shape-mockup d-none d-xl-block z-index-negative" style={{ bottom: "0%", right: "0%", left: "0%" }} >
                    <img class="w-100" src="assets/img/shape/fact-shape-2.svg" alt="svg" />
                </div>
                <div class="container new-container">
                    <div class="row">
                        <div class="col-lg-12 mb-30">
                            <div class="white-title">
                                <span class="sec-subtitle" style={{ color: "#ff681a" }}>Organic Tour to Bharat (India)</span>
                                <h2 class="sec-title h1">Namaste,</h2>
                                <p class="sec-text pe-xl-5 mb-0 t-j">Every corner of the world has its own beauty and something to offer. Likewise, Bharat has something to offer.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">We are organizing this unique tour for the first time ever. People from all across the world have "Bharat" on the tip of their tongues for several reasons. I have been to a lot of places in Bharat and a few parts of the world. My personal experience strongly says that people don’t know and have not met the "Real Bharat". This also stands tall for "Bhartiyas (Indians)" residing abroad and in Bharat. Our eyes, covered with materialistic aspirations and a mechanical lifestyle, have pushed us away from our identity. Organic Tour to Bharat here I meant, “Bharat As It Is” that comprises of Sea of Peace, Spirituality, Yoga, Wisdom and Knowledge. We wish you to experience “Bharat As It Is”. </p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">To cite Bharat’s contribution to the world, Bharat has given the philosophy of, ‘Atithi Devo Bhava’ (The Guest is akin to God) and ‘Vasudhaiva Kutumbakam’ to the world (entire world is one family). It has gifted the world the foundation of mathematics, by inventing "Zero". The list continues with supreme contributions being Yoga, Meditation, Values like Love, Peace, Brotherhood; Gurukul, NalandaUniversity, Takshashila University, Sanskrit, Knowledge of Astronomy, Astrology, Sculptures and Heritage, among others. Environment conservation made its way long back with the initiation of Ayurveda, the Chipko movement for the protection of trees, thereby proving the significance of the environment to our lives</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">Since the dawn of time, the Western world has taken every opportunity to portray Bharat as impoverished. The photos in the newspapers, magazines, and the media at large are the picture they have of Bharat, which is astray from the reality. </p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">I have lived more than 15 years in Ireland and most of the people I met have expressed a wish to visit
                                    Bharat (India). Questions arises, What is Bharat, Who is Bharat and Why Bharat?</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">In the whole universe, Bharat is considered to be a land of Vedas and Upanishads, the ultimate source of inspiration and torch bearer to millions of people throughout the globe. It is from the time immemorial, this sacred land has drawn many geniuses from every quarters of countries to study the different branches in the most popular universities then popularly known as Takshashila and Nalanda Universities.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">It is the land where the human structure and its forms was first conceived and expanded gradually and the wings of its outreach in every nook and corner of this vast modern human structure has literally spread its wings in the whole world.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">From very ancient times, Bharat has produced numerous saints and sages, ascetics and seekers in pursuing their path towards ultimate truth. In every century, there undoubtedly existed, manifested the God incarnate Sage. The land of Bharat is known to be the land of saints and Gods. It is filled with various types of unexplainable things. In ancient times, various saints after doing years of hard meditation, their work and with their patience found the secrets hidden in the Vedas 1,000 years ago. These inventions later came to be known as modern science. Some of the saints came out with such amazing inventions that shocked the kings of those times as well.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">Bhartiya culture has evolved over the ages by Bhartiya ancient Rishis, who at the banks of its holy rivers had ‘discovered’ the Vedic literature – the very foundation of Bhartiya civilization. The term ‘Rishi’ originally denoted the composers and singers of Vedic hymns. However, the Rishi is also a ‘sage’ to whom the Gods revealed the Vedas (knowledge of the eternal truths about the Creator, His creation and means to preserve it). Lot of time I feel, and in my opinion that Bharat is the mother of Universe.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">An insight into the past reveals that the Babylonian Empire, Roman Empire and Egyptian Empire were wiped away over the years, but Bharat has survived and thrived despite the ups and downs faced in the past. The reason, perhaps is that Bharat is blessed by the supreme power since it is popularly known as the "Abode of Gods and Goddesses".</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">Bharat has gone through several transitions where these two Empires played a major role. Islam first arrived in Bharat in the eighth century and by the eleventh, it had firmly established itself as a political force and religious force at later stage. This resulted in the formation of the Delhi Sultanate, which was finally succeeded by the Mughal Empire.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">It was in the 17th century that the Europeans came to Bharat. This coincided with the disintegration of the Mughal Empire, paving the way for regional states. In the contest for supremacy, the English emerged as 'victors'. The Rebellion of 1857–58, which sought to restore Bharat’s supremacy, was crushed; and with the subsequent crowning of Victoria as Empress of Bharat, the incorporation of Bharat into the Empire was complete. The fight for Independence transcended the boundaries of time and demanded the sacrifice of thousands of Bhartiyas. It was then, in 1947, when Bharat saw the dawn of freedom after witnessing hundreds of sleepless nights of struggle.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">Despite the hardships in past, Bharat has thrived again and has made a name in the leading countries of the world. Question arises how and why Bhartiya culture is still surviving? I believe that our tour will give an insight into this. </p>
                            </div>
                            <br />
                            <a href="/destination" class="vs-btn style4">Explore Now</a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="space space-extra-bottom about-layout1 about-layout2">
                
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="title-area">
                                    <span class="sec-subtitle">About Us</span>
                                    <h2 class="sec-title h1">Unique Adventures for Every Interest in Bharat</h2>
                                    <p class="sec-text">We saw a need for travel experiences that go beyond the surface, allowing visitors to connect deeply with the land, its people, and its age-old traditions.</p>
                                    <p class="sec-text">Our passion for authentic cultural and spiritual exploration drives everything we do.</p>
                                    
                                </div>
                                <ul class="about-list3">
                                    <li class="list-item">
                                        <div class="about-icon">
                                            <img src="assets/img/added/journey.png" alt="about-icon" />
                                        </div>
                                        <div class="icon-content">
                                            <h3 class="title">Wellness Journeys</h3>
                                            <p class="text">Rejuvenate with Ayurvedic treatments and yoga.</p>
                                        </div>
                                    </li>
                                    <li class="list-item">
                                        <div class="about-icon">
                                            <img src="assets/img/added/food.png" alt="about-icon" />
                                        </div>
                                        <div class="icon-content">
                                            <h3 class="title">Culinary Tours</h3>
                                            <p class="text">Taste the diverse flavors of India with food adventures.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div class="about-bottom">
                                    {/* <a href="/contact" class="vs-btn style5">Discover More</a> */}
                                    <div class="item2">
                                        <div class="item2__icon">
                                            <img src="assets/img/added/email.png" alt="phone icon 1" />
                                        </div>
                                        <div class="item2__text">
                                            <span>Email:</span>
                                            <a href="/contact">organictoursindia@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                        <div class="img-box4 ms-0">
                                <div class="img-box4__img1">
                                    <img class="img1" src="assets/img/added/Leh.jpg" alt="about" />
                                </div>
                                <div class="img-box4__img2">
                                    <img class="img2" src="assets/img/added/Kutch2.jpg" alt="about" />
                                    <img class="img3" src="assets/img/added/Munnar.jpg" alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

            <section class="space space-extra-bottom blog-wrapper shape-mockup-wrap">
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ bottom: "13%", left: "0%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-xl-8 col-lg-8 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="title-area">
                                <h2 class="sec-title h1">What We Offer</h2>
                                <p class="sec-text">At Organic Tours India, we provide a range of immersive experiences designed to uncover the multifaceted beauty and richness of Bharat. Our offerings are crafted to cater to diverse interests and provide a deep, authentic connection to the heart of India.</p>
                            </div>
                        </div>
                    </div>

                    <Slider {...sliderSettings} className="row vs-carousel">
                        <div className="col-xl-4">
                            <div className="vs-blog blog-style3">
                                <div className="blog-img">
                                    <img src="assets/img/added/Cultural.jpg" alt="Cultural Explorations" />
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">Cultural Explorations</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="vs-blog blog-style3">
                                <div className="blog-img">
                                    <img src="assets/img/added/Spiritual.jpg" alt="Spiritual Retreats" />
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">Spiritual Retreats</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="vs-blog blog-style3">
                                <div className="blog-img">
                                    <img src="assets/img/added/Nature.jpg" alt="Adventure and Nature" />
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">Adventure and Nature</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="vs-blog blog-style3">
                                <div className="blog-img">
                                    <img src="assets/img/added/Events.jpg" alt="Festivals and Events" />
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">Festivals and Events</h2>
                                </div>
                            </div>
                        </div>

                    </Slider>
                    <div class="text-center mb-30 wow fadeInUp pt-lg-2" data-wow-delay="0.3s">
                        <a href="/destination" class="vs-btn">View More</a>
                    </div>
                </div>
            </section>

            
            <Footer />
        </Fragment>
    );
}

export default About;