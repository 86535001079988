
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact';
import Destination from './pages/Destination'

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    
                    <Route path="/" exact element={<Home />} />
                    <Route path='/about-us' exact element={<About />} />
                    <Route path='/contact' exact element={<Contact />} />
                    <Route path='/destination' exact element={<Destination />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
