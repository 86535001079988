import React, { Fragment } from "react";
import Logo from '../assets/logo.png'

function Footer() {
    return (
        <Fragment>
            <div class="offer--layout">
                <div class="container">
                    <div class="offer-block" style={{ backgroundImage: "url('assets/img/bg/offer-bg-4.jpg')", backgroundSize: "cover" }} >
                        <div class="row align-items-center">
                            <div class="col-lg-8 mb-30">
                                <div class="white-title">
                                    <span class="sec-subtitle">Special offer for you</span>
                                    <h2 class="sec-title mb-0">
                                        Start your Journey with a Single Click
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-4 text-md-end text-center mb-30">
                                <a href="/contact" class="vs-btn style5">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="footer-layout4 shape-mockup-wrap" style={{ backgroundImage: "url('assets/img/bg/footer-bg-4.jpg')" }} >
                <div class="container">
                    <div class="footer-newsletter2 pb-0">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer-logo text-center d-block text-md-start">
                                    <a href="/"><img src={Logo} alt="Travolo" class="logo" /></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="social-style1 text-center text-md-end">
                                    <a href="https://www.facebook.com/organictoursindia" rel="noreferrer" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/organictoursindia/" rel="noreferrer" target="_blank"><i class="fab fa-instagram"></i></a>
                                    <a href="https://youtu.be/CnOJsVLJ6GA?si=8LPzoF5PWZlY9Vk1" rel="noreferrer" target="_blank"><i class="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="widget-area2">
                    <div class="container">
                        <div class="row justify-content-between">
                            <div class="col-md-6 col-xl-7">
                                <div class="widget footer-widget">
                                    <div class="vs-widget-about">
                                        <h3 class="widget_title">Join Us</h3>
                                        <p class="footer-text">Discover the true spirit of Bharat with Organic Tours India. Join us for a journey that connects you deeply with the heart of India.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-2">
                                <div class="widget widget_nav_menu footer-widget">  
                                    <h3 class="widget_title">Useful Links</h3>
                                    <div class="menu-all-pages-container">
                                        <ul class="menu">
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/about-us">About Us</a></li>
                                            <li><a href="/destination">Destination</a></li>
                                            <li><a href="/contact">Contact Now</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="copyright-wrap">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-lg-auto">
                                <p class="copyright-text">
                                    Copyright <i class="fal fa-copyright"></i> 2024 <a href="/" style={{ textDecoration: "none" }}>Organic Tours India</a> | All Rights Reserved
                                </p>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <div class="copyright-menu">
                                    <ul class="list-unstyled">
                                        <li><a href="/">Privacy</a></li>
                                        <li><a href="/">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="/" class="scrollToTop scroll-btn"><i class="far fa-arrow-up"></i></a>
        </Fragment>
    );
}

export default Footer;