import React, { Fragment, useState } from "react";
import Footer from "../components/Footer";

const Destination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 9;

    const destinations = [
        {
            img: "assets/img/added/Jagannath.jpg",
            title: "Jagannath puri temple",
            description: "The Jagannath Temple is a Hindu temple dedicated to the god Jagannath, a form of Vishnu in Hinduism. It is located in Puri in the state of Odisha, situated on the eastern coast of India."
        },
        {
            img: "assets/img/added/Amarnath.jpg",
            title: "Amarnath",
            description: "Amarnath, located in union territory of Jammu and Kashmir is one of the most important pilgrimage in India for the worshippers of Lord Shiva. Amarnath Cave is accessible only during the Sharavan months of July-August."
        },
        {
            img: "assets/img/added/padmanabhaswamy-temple.jpg",
            title: "Padmanabham temple in Thiruvanathpuram",
            description: "The Padmanabhaswamy Temple is a Hindu temple dedicated to Vishnu in Thiruvananthapuram, the capital of the state of Kerala, India."
        },
        {
            img: "assets/img/added/Darjeeling.jpg",
            title: "Darjeeling",
            description: "Darjeeling is a town in India's West Bengal state, in the Himalayan foothills.It's famed for the distinctive black tea grown on plantations that dot its surrounding slopes."
        },
        {
            img: "assets/img/added/Srirangam.jpg",
            title: "Ranganathaswamy Temple, Srirangam",
            description: "The Ranganathaswamy Temple is a Hindu temple dedicated to Ranganatha (a form of Vishnu) and is located in Srirangam, Tiruchirapalli, Tamil Nadu, India."
        },
        {
            img: "assets/img/added/Sikkim.jpg",
            title: "Sikkim",
            description: "Sikkim is a state in northeast India, bordered by Bhutan, Tibet and Nepal. Part of the Himalayas, the area has a dramatic landscape that includes India’s highest mountain, 8,586m Kangchenjunga."
        },
        {
            img: "assets/img/added/Memorial.jpg",
            title: "Vivekananda Rock Memorial",
            description: "Vivekananda Rock Memorial is a monument and popular tourist attraction in Kanyakumari, India's southernmost tip. The memorial stands on one of the two rocks located about 500 meters off mainland of Vavathurai, Tamil Nadu."
        },
        {
            img: "assets/img/added/Rishikesh.jpg",
            title: "Rishikesh",
            description: "Rishikesh is a city in India’s northern state of Uttarakhand, in the Himalayan foothills beside the Ganges River. The river is considered holy, and the city is renowned as a center for studying yoga and meditation."
        },
        {
            img: "assets/img/added/varanasi.jpg",
            title: "Varanasi",
            description: "Situated on the banks of River Ganga in Uttar Pradesh, Varanasi is considered as one of the holiest cities, drawing tourists who seek spiritual awareness and liberation."
        },
        {
            img: "assets/img/added/Pondicherry.jpg",
            title: "Pondicherry",
            description: "A fascinating, small town with enough elements to charm its visitors, Pondicherry whose official name was changed to Puducherry in 2006, was once the largest French colony in India."
        },
        {
            img: "assets/img/added/Caves.jpg",
            title: "Elephanta Caves",
            description: "A group of ancient rock cut cave temples that can be reached via a ferry from the Gateway of India and are a designated UNESCO World Heritage Site."
        },
        {
            img: "assets/img/added/kornak.jpg",
            title: "Sun Temple Konark",
            description: "The Sun Temple in Konark is a renowned UNESCO World Heritage Site located in Konark, Odisha. The Sun Temple is famous for its impressive architectural design and intricate stone carvings."
        },
        {
            img: "assets/img/added/mahabaleshwar4.jpg",
            title: "Mahabaleshwar",
            description: "Mahabaleshwar is the ideal hill station getaway from Mumbai with its mesmerising waterfalls, old forts, historic temples and long stretches of vibrant strawberry plantations."
        },
        {
            img: "assets/img/added/ujjain.jpg",
            title: "Ujjain",
            description: "One of the seven sacred Hindu cities, Ujjain is located on the banks of river Kshipra and is land of the magnificent Kumbh Mela. Iconic religious sites and a web of lively lanes are a vibrant display of Ujjain’s art and heritage."
        },

        {
            img: "assets/img/added/Abu.jpg",
            title: "Mount Abu",
            description: "The only hill station in Rajasthan and the former summer capital of Rajputana rulers, Mount Abu affords a stunning landscape complemented by great weather at an altitude of 1220 metres."
        },
        {
            img: "assets/img/added/Jodhpur.jpg",
            title: "Jodhpur",
            description: "Known for the magnificent Mehrangarh Fort, famous palaces and lakes, Jodhpur is a popular tourist destination, divided into the Old City and New City by a 10 km long wall."
        },
        {
            img: "assets/img/added/Hampi.jpg",
            title: "Hampi",
            description: "The renowned UNESCO World Heritage Site and the once-magnificent capital of the Vijayanagara Empire attracts backpackers and pilgrims in equal numbers due to its intriguing ruins of forts and temples."
        },
        {
            img: "assets/img/added/manali.jpg", 
            title: "Manali",
            description: "Nestled on the banks of River Beas, Manali will take your breath away with its snow-kissed peaks, lush valleys and heart-pumping adventure experiences in the heart of Himalayas."
        },
        {
            img: "assets/img/added/Amritsar.jpg",
            title: "Amritsar",
            description: "Home to the legendary Sikh shrine Golden Temple, Amritsar is fondly called Ambarsar and will steal your heart with its colourful bazaars, lip-smacking street food, shopping hubs and historical monuments."
        },
        {
            img: "assets/img/added/Rameshwaram.jpg",
            title: "Rameshwaram",
            description: "Made famous by the epic Ramayana, Rameshwaram is a quaint beach town and a popular Hindu pilgrimage destination that also attracts nature lovers and adventure enthusiasts."
        },
        {
            img: "assets/img/added/Mathura.jpg",
            title: "Mathura",
            description: "Famous as the birthplace of Lord Krishna and one among the 7 holy cities for Hindus, this city alongside the Yamuna river is an important pilgrimage site."
        },
        {
            img: "assets/img/added/Mahabodhi.jpg",
            title: "Mahabodhi Temple",
            description: "The current Mahabodhi Temple Complex at Bodh Gaya consists of the 50 m high grand Temple, the Vajrasana, the sacred Bodhi Tree, and other six sacred sites related to Buddha's enlightenment."
        },
        {
            img: "assets/img/added/Gwalior.jpg",
            title: "Gwalior",
            description: "As the cradle of Indian culture and heritage for many centuries, Gwalior is a timeless treasure of art and music. The cityscape is adorned with historical monuments and a hilltop fort that are souvenirs of the dynasties that ruled here."
        },
        {
            img: "assets/img/added/Mysuru.jpg",
            title: "Mysore",
            description: "The ‘City of Palaces’, Mysore revels in its vibrant history, and rich arts and culture. It allures tourists with its grand palaces, majestic temples, and landscaped gardens."
        },
        {
            img: "assets/img/added/Dalhousie.jpg",
            title: "Dalhousie",
            description: "Located in the lush green surroundings of Chamba district in Himachal Pradesh, Dalhousie is a serene town that still has its natural beauty well preserved."
        },
        {
            img: "assets/img/added/Kutch.jpg",
            title: "Kutch",
            description: "Great Rann of Kutch is famous for its natural and festive charm. A visit to the tent city is a lifetime memory with the lavish hospitality offered by the Rann of Kutch tourism."
        },
        {
            img: "assets/img/added/Mysuru.jpg",
            title: "Lonavala",
            description: "Popular tourist place to visit in India during Monsoon is Lonavala. It is the most visited hill station in Maharashtra and is a popular destination for camping, trekking and other thrilling sports."
        },
    ];

    const totalPages = Math.ceil(destinations.length / postsPerPage);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentDestinations = destinations.slice(indexOfFirstPost, indexOfLastPost);

    // Handle pagination
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <Fragment>
            <div className="breadcumb-wrapper" style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url('/assets/img/added/ban.jpg')" }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Destinations</h1>
                        <div className="breadcumb-menu-wrap">
                            <ul className="breadcumb-menu">
                                <li><a href="/">Home</a></li>
                                <li>Destinations</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vs-blog-wrapper space-top space-extra-bottom shape-mockup-wrap">
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ bottom: "5%", right: "0%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ top: "29%", left: "2%", zIndex: "1", width: "237px" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div className="container">
                    <div className="row gx-30">
                        <div className="col-lg-12">
                            <div className="row">
                                {currentDestinations.map((destination, index) => (
                                    <div key={index} className="col-xl-4 col-md-6 col-sm-12 col-12">
                                        <div className="vs-blog blog-style2">
                                            <div className="blog-img">
                                                <img src={destination.img} className="fix-img" alt="blog" />
                                            </div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">{destination.title}</h2>
                                                <p>{destination.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="vs-pagination pt-lg-2">
                                <ul>
                                    <li>
                                        <a 
                                            href="#!" 
                                            onClick={() => paginate(currentPage - 1)} 
                                            className={currentPage === 1 ? 'disabled' : ''}>
                                            <i className="fas fa-chevron-left"></i>
                                        </a>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li key={i + 1}>
                                            <a 
                                                href="#!" 
                                                onClick={() => paginate(i + 1)} 
                                                className={currentPage === i + 1 ? 'active' : ''}>
                                                {i + 1}
                                            </a>
                                        </li>
                                    ))}
                                    <li>
                                        <a 
                                            href="#!" 
                                            onClick={() => paginate(currentPage + 1)} 
                                            className={currentPage === totalPages ? 'disabled' : ''}>
                                            <i className="fas fa-chevron-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Destination;
