import React, { Fragment, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        pauseOnHover: true, // Add this line to stop autoplay on hover
    };
    const handleMouseEnter = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
    };

    const handleMouseLeave = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
        }
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const swipers = {
        dots: false,
        infinite: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };



    return (
        <Fragment>
            <Header />



            <section class="hero-layout1">
                <div>
                    <Slider {...sliderSettings} ref={sliderRef} onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <div className="vs-carousel hero-slider2" data-slide-show="1" data-fade="true">
                            <div className="hero-slide hero-mask banner-1" >
                                <div className="container">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-lg-8">
                                            <div className="hero-content">
                                                <span className="hero-subtitle">Let's Go Now</span>
                                                <h1 className="hero-title">
                                                    Welcome to Organic Tours India (Bharat)
                                                </h1>
                                                <p className="hero-text">
                                                    Every corner of the world has its own beauty and something to offer. Likewise, Bharat has something
                                                    to offer.
                                                </p>
                                                <div className="">
                                                    <a href="/about-us" className="vs-btn style4">Read More</a>
                                                    <div className="hero-form2">
                                                        <img src="assets/img/banner/hero-bag.png" className="bag-img" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-slide hero-mask banner p-more">
                            <video className="background-video" autoPlay muted loop playsInline>
                                <source src="assets/img/added/vid.mp4" type="video/mp4" />
                            </video>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="hero-content box-content">
                                            <span className="hero-subtitle">Let's Go Now</span>
                                            <h1 className="hero-title">Welcome to Organic Tours India (Bharat)</h1>
                                            <p className="hero-text">
                                                Bharat, a land of diverse cultures, stunning landscapes, and ancient traditions, is more than just a destination—it's an experience.
                                            </p>
                                            <a href="/about-us" className="vs-btn style4">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div>
                        <button className="icon-btn prev-btn" onClick={() => sliderRef.current.slickPrev()}>
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button className="icon-btn next-btn" onClick={() => sliderRef.current.slickNext()}>
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </section>

            <section class="space shape-mockup-wrap space-extra-bottom shape-mockup-wrap">
                <div class="shape-mockup d-none d-xl-block ripple-animation z-index-negative" style={{ top: "10%", left: "5%" }} >
                    <img src="assets/img/shape/Ballon.png" alt="svg" />
                </div>
                <div class="shape-mockup d-none d-xl-block jump z-index-negative" style={{ top: "10%", right: "10%" }}>
                    <img src="assets/img/shape/up-arrow.png" alt="svg" />
                </div>
                <div class="shape-mockup d-none d-xl-block jump z-index-negative" style={{ bottom: "0%", left: "0%" }} >
                    <img class="plane2" src="assets/img/shape/plane2.png" alt="svg" />
                </div>
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ bottom: "13%", right: "0%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-xl-5">
                            <div class="about-content">
                                <div class="title-area">
                                    <span class="sec-subtitle">Who we are</span>
                                    <h2 class="sec-title h1">
                                        Guiding Your Journey to India's Soul
                                    </h2>
                                    <p class="sec-text">We are passionate about unveiling the true essence of Bharat, a land rich in peace, spirituality, and timeless wisdom. For the first time ever, we are organizing this unique tour to connect you with the "Real Bharat"—a side of India that remains hidden beneath the layers of modernity and materialism.</p>
                                    <p class="sec-text">Our mission is to help you experience Bharat as it is—immersing you in the serene beauty of its landscapes, the depth of its spiritual practices, and the profound knowledge that has shaped its culture for millennia.</p>
                                </div>
                                <ul class="about-list1">
                                    <li>Immersive Experiences</li>
                                    <li>Authentic Connections</li>
                                    <li>Personalized Journeys</li>
                                    <li>Local Expertise</li>
                                    <li>Cultural Connections</li>
                                    <li>Sustainable Travel</li>
                                </ul>
                                <a href="/about-us" class="vs-btn style4">View More</a>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="img-box3">
                                <img class="img1" src="assets/img/added/about-1.png" alt="about " />
                                <div class="bottom-img">
                                    <img class="img2" src="assets/img/added/about-2.png" alt="about " />
                                    <img class="img3" src="assets/img/added/about-3.png" alt="about " />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="space shape-mockup-wrap fact--layout1" style={{ position: "relative" }}>
                <video className="background-video " autoPlay muted loop playsInline>
                    <source src="assets/img/added/welcome.mp4" type="video/mp4" />
                </video>
                <div class="video-overlay"></div>

                <div class="shape-mockup d-none d-xl-block z-index-negative" style={{ top: "0%", right: "0%", left: "0%" }}>
                    <img class="w-100" src="assets/img/shape/fact-shape-1.svg" alt="svg" />
                </div>

                <div class="shape-mockup d-none d-xl-block z-index-negative" style={{ bottom: "0%", right: "0%", left: "0%" }}>
                    <img class="w-100" src="assets/img/shape/fact-shape-2.svg" alt="svg" />
                </div>
                <div class="container new-container">
                    <div class="row">
                        <div class="col-lg-12 mb-30">
                            <div class="white-title">
                                <span class="sec-subtitle" style={{ color: "#ff681a" }}>Organic Tour to Bharat (India)</span>
                                <h2 class="sec-title h1">Namaste,</h2>
                                <p class="sec-text pe-xl-5 mb-0 t-j">We are organizing this unique tour for the first time ever. People from all across the world have "Bharat" on the tip of their tongues for several reasons. I have been to a lot of places in Bharat and a few parts of the world. My personal experience strongly says that people don’t know and have not met the "Real Bharat". This also stands tall for "Bhartiyas (Indians)" residing abroad and in Bharat. Our eyes, covered with materialistic aspirations and a mechanical lifestyle, have pushed us away from our identity. Organic Tour to Bharat here I meant, “Bharat As It Is” that comprises of Sea of Peace, Spirituality, Yoga, Wisdom and Knowledge. We wish you to experience “Bharat As It Is”.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">To cite Bharat’s contribution to the world, Bharat has given the philosophy of, ‘Atithi Devo Bhava’ (The Guest is akin to God) and ‘Vasudhaiva Kutumbakam’ to the world (entire world is one family). It has gifted the world the foundation of mathematics, by inventing "Zero". The list continues with supreme contributions being Yoga, Meditation, Values like Love, Peace, Brotherhood; Gurukul, NalandaUniversity, Takshashila University, Sanskrit, Knowledge of Astronomy, Astrology, Sculptures and Heritage, among others. Environment conservation made its way long back with the initiation of Ayurveda, the Chipko movement for the protection of trees, thereby proving the significance of the environment to our lives.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">Since the dawn of time, the Western world has taken every opportunity to portray Bharat as impoverished. The photos in the newspapers, magazines, and the media at large are the picture they have of Bharat, which is astray from the reality.</p>
                                <br />
                                <p class="sec-text pe-xl-5 mb-0 t-j">Despite the hardships in past, Bharat has thrived again and has made a name in the leading countries of the world. Question arises how and why Bhartiya culture is still surviving? I believe that our tour will give an insight into this. </p>
                            </div>
                            <br />
                            <a href="/about-us" class="vs-btn style4">Know More</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="space gallery2 shape-mockup-wrap">
                <div className="shape-mockup d-none d-xl-block jump z-index-negative" style={{ top: "20%", left: "0%" }}>
                    <img src="assets/img/shape/visit-left.png" alt="svg" />
                </div>
                <div className="shape-mockup d-none d-xl-block jump z-index-negative" style={{ top: "20%", right: "0%" }}>
                    <img src="assets/img/shape/visit-right.png" alt="svg" />
                </div>
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ top: "9%", left: "7%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div className="shadow-color"></div>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="title-area">
                                <span className="sec-subtitle">Go & Discover</span>
                                <h2 className="sec-title h1">Join Us on YouTube!</h2>
                                <p className="sec-text">
                                    Despite the hardships in past, Bharat has thrived again and has made a name in the leading countries of the world. Question arises how and why Bhartiya culture is still surviving? I believe that our tour will give an insight into this.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-style-2 wow fadeInUp" data-wow-delay="0.3s">
                        <video className="custom-video-bg" autoPlay muted loop playsInline>
                            <source src="assets/img/added/welcome.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div class="text-center mt-2 mb-30 pt-lg-2 wow fadeInUp" data-wow-delay="0.3s" >
                    <a href="https://www.youtube.com/watch?v=CnOJsVLJ6GA" target="_blank" class="vs-btn" rel="noreferrer">Join Us</a>
                </div>
            </section>


            <section class="space space-extra-bottom bg-light shape-mockup-wrap" style={{ backgroundImage: "url('assets/img/shape/Bg.png')" }} data-bg-src="">
                <div class="shape-mockup d-none d-xl-block spin z-index-negative" style={{ top: "-20%", right: "-8%" }} >
                    <img src="assets/img/shape/circle1.png" alt="circle" />
                </div>
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ bottom: "13%", left: "0%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div class="container">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-7 col-md-8">
                            <div class="title-area">
                                <span class="sec-subtitle">Heritage Destinations</span>
                                <h2 class="sec-title h1">Revisit History on a Vacation</h2>
                                <p class="sec-text">Step back in time and explore the rich heritage of Bharat with our curated tours. Visit ancient temples, historic forts, and UNESCO World Heritage Sites that tell the tales of India’s glorious past. </p>
                            </div>
                        </div>
                    </div>
                    <div className="destinationSlide">
                        <Slider {...settings} class="row ">
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Caves.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Elephanta Caves</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/varanasi.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Varanasi</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/kornak.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Sun Temple Konark</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/ujjain.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Ujjain</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Mahabodhi.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Mahabodhi Temple</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Gwalior.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Gwalior</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Mysuru.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Mysore</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Puri.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Jagannath puri</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Padmanabham.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Padmanabha temple</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Srirangam.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Srirangam temple</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 slide-container">
                                <div className="destination-style1">
                                    <img src="assets/img/added/Kanyakumari.jpg" alt="destination" />

                                    <div className="destination-info">
                                        <h4 className="destination-name text-white">Vivekananda Rock Memorial</h4>
                                        <p className="destination-text">Explore</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div class="text-center mb-30 wow fadeInUp pt-lg-2" data-wow-delay="0.3s">
                    <a href="/destination" class="vs-btn">View More</a>
                </div>
            </section>

            <section class="tour--layout4 space space-extra-bottom shape-mockup-wrap">
                <div class="shape-mockup d-none d-xl-block  z-index-negative" style={{ bottom: "13%", right: "0%", zIndex: "1" }} >
                    <img src="assets/img/shape/walk.png" alt="circle" />
                </div>
                <div class="container ">
                    <div class="row justify-content-center text-center">
                        <div class="col-xl-9 col-lg-8 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="title-area">
                                <span class="sec-subtitle">Uncover India's Hidden Gems</span>
                                <h2 class="sec-title h1">Journey to the Most Beautiful Places</h2>
                                <p class="sec-text">Experience the diverse beauty of India, from the majestic peaks of the Himalayas to the tranquil backwaters of Kerala. Explore stunning landscapes, lush valleys, and breathtaking vistas that showcase the natural splendor of this incredible country. Your journey through India's most scenic destinations promises unforgettable memories at every turn.</p>
                            </div>
                        </div>
                    </div>
                    
                    <Slider {...swipers} class="row vs-carousel" data-slide-show="3" data-arrows="false" data-lg-slide-show="3" data-md-slide-show="2"
                        data-sm-slide-show="1">
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/tirthan-river.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    
                                    <h3 class="package-title">Tirthan Valley</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Himachal Pradesh</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/Gangtok.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Gangtok</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Sikkim</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/ilDarjeeling.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Darjeeling</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>West Bengal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/Alleppey.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Alleppey</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Kerala</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/Mussoorie.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Mussoorie</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Uttarakhand</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/Coorg.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Coorg</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Karnataka</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/Dibrugarh.png" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Dibrugarh</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Assam</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/tawang.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Tawang</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Arunachal Pradesh</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-6 slide-container">
                            <div class="package-style3">
                                <div class="package-img">
                                    <img class="w-100 img-pac" src="assets/img/added/Munnar-cap.jpg" alt="Package" />
                                </div>
                                <div class="package-content">
                                    <h3 class="package-title">Munnar</h3>
                                    <div class="package-meta">
                                        <p class="package-text m-0" style={{ color: "#ff681a" }}>Kerala</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div class="text-center mb-30 wow fadeInUp pt-lg-2" data-wow-delay="0.3s">
                    <a href="/destination" class="vs-btn">View More</a>
                </div>
            </section>

            <section class="space-extra-bottom space shape-mockup-wrap">
                <div class="shape-mockup d-none d-xl-block ripple-animation z-index-negative" style={{ bottom: "10%", right: "13%" }}>
                    <img src="assets/img/shape/circle.png" alt="svg" />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-md-6 col-sm-6 col-12">
                            <div class="features-style1">
                                <div class="features-bg" style={{ backgroundImage: "url('assets/img/shape/features.png')", backgroundSize: "cover" }} ></div>
                                <div class="features-image">
                                    <img src="assets/img/added/spiritual.png" alt="" />
                                </div>
                                <div class="features-content">
                                    <h3 class="features-title">Spiritual Journeys</h3>
                                    <p class="features-text">
                                        Visit sacred sites, ancient temples, and ashrams, connecting
                                        with India’s spiritual essence through meditation, yoga, and
                                        traditional rituals.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 col-sm-6 col-12">
                            <div class="features-style1 h-300">
                                <div class="features-bg" style={{ backgroundImage: "url('assets/img/shape/features.png')", backgroundSize: "cover" }} ></div>
                                <div class="features-image">
                                    <img src="assets/img/added/traditional-dance.png" alt="" />
                                </div>
                                <div class="features-content">
                                    <h3 class="features-title">Cultural Experiences</h3>
                                    <p class="features-text">
                                        Dive deep into India’s rich heritage with guided tours that
                                        explore traditional arts, crafts, and local customs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 col-sm-6 col-12">
                            <div class="features-style1">
                                <div class="features-bg" style={{ backgroundImage: "url('assets/img/shape/features.png')", backgroundSize: "cover" }} ></div>
                                <div class="features-image">
                                    <img src="assets/img/added/culinary.png" alt="" />
                                </div>
                                <div class="features-content">
                                    <h3 class="features-title">Culinary Adventures</h3>
                                    <p class="features-text">
                                        Savor the diverse flavors of India with food tours that
                                        introduce you to regional cuisines, cooking classes, and
                                        farm-to-table experiences.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 col-sm-6 col-12">
                            <div class="features-style1">
                                <div class="features-bg" style={{ backgroundImage: "url('assets/img/shape/features.png')", backgroundSize: "cover" }} ></div>
                                <div class="features-image">
                                    <img src="assets/img/added/mental-health.png" alt="" />
                                </div>
                                <div class="features-content">
                                    <h3 class="features-title">Wellness Retreats</h3>
                                    <p class="features-text">
                                        Rejuvenate your mind, body, and soul with Ayurvedic
                                        treatments, yoga sessions, and wellness practices rooted in
                                        ancient Indian traditions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="benefits--layout1 space-bottom">
                <div class="img1" style={{ backgroundImage: "url('assets/img/bg/faq-bg-1-1.png')" }} ></div>
                <div class="container">
                    <div class="row justify-content-end">
                        <div class="col-lg-6 mb-30">
                            <div class="title-area">
                                <span class="sec-subtitle">FAQ’s</span>
                                <h2 class="sec-title h1">
                                    Experience the World with our Organic Tour India
                                </h2>
                            </div>
                            <div class="accordion accordion-style1" id="faqVersion1">
                                <div class="accordion-item">
                                    <div class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is Organic Tours India (Bharat)?
                                        </button>
                                    </div>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#faqVersion1">
                                        <div class="accordion-body">
                                            <p>
                                                Organic Tours India (Bharat) is a travel company that
                                                offers immersive cultural, spiritual, and wellness tours
                                                across India. Our focus is on providing authentic
                                                experiences that allow travelers to connect deeply with
                                                the traditions, history, and spirituality of Bharat
                                                (India).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What types of tours do you offer?
                                        </button>
                                    </div>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#faqVersion1">
                                        <div class="accordion-body">
                                            <p>
                                                We offer a variety of tours, including cultural
                                                experiences, spiritual journeys, wellness retreats,
                                                culinary adventures, nature and adventure tours, and more.
                                                Each tour is designed to give you a genuine taste of
                                                India's rich heritage and spiritual essence.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How do your tours work?
                                        </button>
                                    </div>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#faqVersion1">
                                        <div class="accordion-body">
                                            <p>
                                                Our tours are carefully curated by experts who have an
                                                intimate understanding of India’s diverse cultures and
                                                spiritual practices.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                            What if I have more questions?
                                        </button>
                                    </div>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                        data-bs-parent="#faqVersion1">
                                        <div class="accordion-body">
                                            <p>
                                                If you have any additional questions or need more
                                                information, feel free to contact us at 
                                                <a href="/" style={{ color: "#ff681a" }}> organictoursindia@gmail.com</a>. We’re here
                                                to help and make your journey as smooth and
                                                enjoyable as possible.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shape-mockup d-none d-xl-block z-index-negative" style={{ right: "0%", bottom: "0%", zIndex: "-1" }} >
                    <img src="assets/img/shape/bag.png" alt="bag" />
                </div>
            </section>
            <Footer />
        </Fragment>
    );
}

export default Home;